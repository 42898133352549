import RolesEnum from '../../enums/RolesEnum';
import StoresConfig from '../../../configs/store-config';
import { isNullableType } from 'graphql';
import jwt from 'jsonwebtoken';

/**
 * @param auth {import('react-oidc-context').AuthContextProps}
 * @returns {string[]}
 */
export const getRoles = (auth) =>
  (auth && auth.user && auth.user.access_token && jwt.decode(auth.user.access_token).realm_access.roles) || []

/**
 * @param auth {import('react-oidc-context').AuthContextProps}
 * @returns {string[]}
 */
export const getStores = (auth) => {
  var storeStr = "";
  var store_custom = "";
  var newStores = "";
  const roles = getRoles(auth);
  //Validação e separação de várias stores (Attributes do keycloak) para um array
  store_custom = auth && auth.user && auth.user.access_token && jwt.decode(auth.user.access_token).stores
  if (store_custom !== undefined || isNullableType(store_custom)){
    storeStr = store_custom.toString();
    newStores = storeStr.trim().split(",");
  }

  return roles.includes(RolesEnum.ADMIN)
    ? Object.keys(StoresConfig)
    : newStores  || [];
}

/**
 * @param roles {string[]}
 * @param rolesAllowed {string[]}
 * @returns {boolean}
 */
export const roleCheck = (roles, rolesAllowed) => {
  if (!rolesAllowed) return true;
  for (let userRole of roles) {
    if (rolesAllowed.includes(userRole)) return true;
  }
  return false;
}
/**
 * @param auth {import('react-oidc-context').AuthContextProps}
 * @returns {string | undefined}
 */
export const getMetabaseKey = (auth) =>
  auth && auth.user && auth.user.access_token && jwt.decode(auth.user.access_token).metabase

export const getUsername = (auth) =>
  auth && auth.user && auth.user.profile.preferred_username


export default {getRoles, getStores, roleCheck, getMetabaseKey, getUsername}