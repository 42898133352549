import React, { useState, useCallback, useEffect } from 'react';
import { useAuth } from "react-oidc-context";
import KeyCloakHelper from '../helpers/KeyCloakHelper';

const SELECTED_STORE_KEY = 'omni-dashboard:selected-store';

export const SelectedStoreContext = React.createContext('');

export function SelectedStoreProvider({ children }) {
  const auth = useAuth();
  const [selectedStore, setSelectedStoreState] = useState(null);

  const setSelectedStore = useCallback(store => {
    window.localStorage.setItem(SELECTED_STORE_KEY, store);
    setSelectedStoreState(store);
  });

  useEffect(() => {
    const saved = window.localStorage.getItem(SELECTED_STORE_KEY);
    if (
      saved &&
      auth.isAuthenticated &&
      KeyCloakHelper.getStores(auth).includes(saved)
    ) {
      setSelectedStore(saved);
    } else if (auth.isAuthenticated)
      setSelectedStore(KeyCloakHelper.getStores(auth)[0]);
  }, [auth.isAuthenticated, auth]);
  
  return (
    <SelectedStoreContext.Provider value={[selectedStore, setSelectedStore]}>
      {children}
    </SelectedStoreContext.Provider>
  );
}
