import React, { useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import KeyCloakHelper from '../utils/helpers/KeyCloakHelper';
import { useAuth } from 'react-oidc-context';

function PrivateRoute({
  component: Component,
  history,
  rolesAllowed,
  ...props
}) {
  const auth = useAuth()

  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const isAuthorized =
      auth &&
      auth.user &&
      auth.user.access_token &&
      KeyCloakHelper.roleCheck(KeyCloakHelper.getRoles(auth), rolesAllowed);
    setAuthorized(isAuthorized);
    // if (initialized && !isAuthorized) history.push('/'); //TODO verificar isso
  }, [auth && auth.user && auth.user.access_token]);

  const renderCtl = routeProps => <Component {...routeProps}> </Component>;

  return authorized ? <Route render={renderCtl} {...props} /> : null;
}

export default withRouter(PrivateRoute);
