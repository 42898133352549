import {UserManager, WebStorageStateStore} from "oidc-client-ts";

/**
 * @type {import('oidc-client-ts').OidcClientSettings}
 */
const oidcSettings= {
  authority: `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}`,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: `${window.location.origin}${window.location.pathname}`,
  post_logout_redirect_uri: window.location.origin,
}

/**
 * @type {import('oidc-client-ts').UserManager}
 */
export const userManager = new UserManager({
  ...oidcSettings,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  monitorSession: true,
});

/**
 * @type {() => void}
 */
export const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};