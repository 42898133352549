import React, { useState } from 'react';
import { LoadingLayer } from '../../components/LoadingLayer';
import { useAuth } from 'react-oidc-context';

export const LoadingContext = React.createContext(false);

export function LoadingProvider({ children }) {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={[loading || auth.isLoading, setLoading]}>
      {(loading || auth.isLoading) && <LoadingLayer />}
      {children}
    </LoadingContext.Provider>
  );
}
