import React, { Component, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import SideMenu from './pages/SideMenu';
import LogoImg from './assets/icon-omnilogic-white.png';
import LogoImgFull from './assets/logo-omnilogic-white.png';
import Routes from './routes';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Layout } from 'antd';
import { MainTheme } from './styles/theme';
import ExpandedMenuContext from './utils/contexts/ExpandedMenuContext';
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context';
import {
  userManager, onSigninCallback
} from './configs/keycloak-config';
import { LoadingProvider } from './utils/contexts/LoadingContext';
import { SelectedStoreProvider } from './utils/contexts/SelectedStoreContext';
import { TemplateContextProvider } from './pages/intelligentStore/personalShop/dynamic-Showcase/context/TemplateContext';

const { Content, Sider } = Layout;

const Logo = styled.img``;

/**
 * @type {React.FC<{children: React.ReactNode}>}
 */
export function RequireIsLoggedIn({
                               children,
                             }){
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  /**
   * Do auto sign in.
   *
   * See {@link https://github.com/authts/react-oidc-context?tab=readme-ov-file#automatic-sign-in}
   */
  useEffect(() => {
    console.log("Login effect")
    if (!(hasAuthParams() || auth.isAuthenticated || auth.activeNavigator || auth.isLoading || hasTriedSignin)) {
      console.log("Gonna Login")
      void auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return (
      <>
      </>
    );
  }
  if (auth.error && auth.error.message) {
    return (
      <>
        <h1>We've hit a snag</h1>
        <>{auth.error.message}</>
      </>
    );
  }
  if (!auth.isAuthenticated) {
    return (
      <>
        <h1>We've hit a snag</h1>
        <>Unable to sign in</>
      </>
    );
  }
  return <>{children}</>
}

class App extends Component {
  state = {
    collapsed: false
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    return (
      <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
        <LoadingProvider>
          <RequireIsLoggedIn>
            <SelectedStoreProvider>
              <DndProvider backend={Backend}>
                <ThemeProvider theme={MainTheme}>
                  <BrowserRouter>
                    <Layout style={{ minHeight: '100vh' }}>
                      <Sider
                        collapsible
                        width={280}
                        collapsed={this.state.collapsed}
                        onCollapse={this.onCollapse}
                        style={{
                          backgroundColor: MainTheme.mainColor,
                          display: 'flex',
                          justifyContent: 'center',
                          overflow: 'auto',
                          height: '100vh',
                          left: 0
                        }}
                      >
                        <Logo
                          className='logo'
                          src={this.state.collapsed ? LogoImg : LogoImgFull}
                          alt='logo'
                          style={{
                            width: '100%',
                            padding: this.state.collapsed
                              ? '10px 16px'
                              : '10px 32px',
                            marginBottom: '60px'
                          }}
                        />
                        <SideMenu />
                      </Sider>
                      <ExpandedMenuContext.Provider value={!this.state.collapsed}>
                        <Layout style={{ background: '#fff', padding: 0 }}>
                          <Content
                            style={{
                              padding: '0 16px',
                              overflow: 'auto',
                              height: '100vh'
                            }}
                          >
                          <TemplateContextProvider>
                            <Routes />
                          </TemplateContextProvider>
                          </Content>
                        </Layout>
                      </ExpandedMenuContext.Provider>
                    </Layout>
                  </BrowserRouter>
                </ThemeProvider>
              </DndProvider>
            </SelectedStoreProvider>
          </RequireIsLoggedIn>
        </LoadingProvider>
      </AuthProvider>
    );
  }
}

export default App;
